<template>
  <b-container class="mb-7">
    <h1>Goldrush</h1>
    <b-alert v-model="alert.model" variant="danger" dismissible fade>
      {{ alert.text }}
    </b-alert>
    <b-row>
      <CreateGoldrushModal v-if="masterEventState === 'none'" :edit="false" />
      <ActivateGoldrushModal
        v-if="masterEventState === 'created'"
        :id="masterEventData.id"
        :masterTicketFooter="masterEventData.ticketFooter"
      />
      <CreateGoldrushModal v-if="masterEventState === 'created'" :edit="true" :masterEventData="masterEventData" />

      <EditFutureGoldrushRafflesModalV2
        v-if="masterEventState === 'activated'"
        :id="masterEventData.id"
        :canEditFutureRaffles="canEditFutureRaffles"
        @updated="onFutureRafflesUpdated"
      />
      <div>
        <b-button v-b-toggle.goldrush-master-event-collapse variant="outline-secondary">
          Master Event Details
        </b-button>
      </div>
      <!-- Below we steal the banner image from the first event in the list as the master event doesn't include it. -->
      <GoldrushMasterEventModal
        v-if="masterEventState !== 'none' && raffleImage"
        :masterEventData="masterEventData"
        :logoUrl="raffleImage"
      />
    </b-row>

    <b-row class="event-table">
      <b-col>
        <b-form inline>
          <b-checkbox name="show-paid" v-model="hideClosedDraws" switch />
          <label for="show-paid">Hide Closed Draws</label>
        </b-form>
      </b-col>
    </b-row>

    <GenericTable
      :data="data"
      :columns="columns"
      :pagination="pagination"
      :loading="loading"
      :actions="true"
      @updateCurrentPage="updateCurrentPage"
      @sort="sort"
      class="goldrush-raffles mt-4"
    >
      <template #startDate="{ row }">
        {{ formatDateTime(parseISO(row.startDate)) }}
      </template>
      <template #endDate="{ row }">
        {{ formatDateTime(parseISO(row.endDate)) }}
      </template>
      <template #status="{ row }">
        <b-badge variant="secondary" v-if="row.active && row.ended">Ended</b-badge>
        <b-badge variant="dark" v-else-if="row.ended && row.winnerName">Closed</b-badge>
        <b-badge variant="success" v-else-if="row.active && !row.ended">Active</b-badge>
        <b-badge variant="light" v-else>Pending</b-badge>
      </template>
      <template #startingPot="{ row }">
        {{ formatCurrency(row.startingPot) }}
      </template>
      <template #jackpot="{ row }">
        {{ formatCurrency(row.jackpot) }}
      </template>
      <template #winnerTicketNumber="{ row }">
        {{ row.winnerTicketNumber ? row.winnerTicketNumber : '' }}
      </template>
      <template #winnerName="{ row }">
        {{ row.winnerName ? row.winnerName : '' }}
      </template>
      <template #ticketActive="{ row }">
        <b-badge variant="primary" v-if="row.ticketActive === true">Winner</b-badge>
        <b-badge variant="light" v-if="row.ticketActive === false">Roll Over</b-badge>
      </template>
      <template #actions="{ row }">
        <router-link
          :to="{ path: `/goldrush/raffle/${row.id}` }"
          class="p-2 focus:ring focus:ring-green-200 rounded-lg text-gray-800 button-link"
          v-b-tooltip.hover
          title="View Raffle"
        >
          <font-awesome-icon class="text-xl" :icon="['far', 'eye']" />
          <span class="sr-only">View Raffle</span>
        </router-link>
      </template>
    </GenericTable>
  </b-container>
</template>

<script>
import CreateGoldrushModal from '@/components/CreateGoldrushModal';
import ActivateGoldrushModal from '@/components/ActivateGoldrushModal';
import EditFutureGoldrushRafflesModalV2 from '@/components/modals/EditFutureGoldrushRafflesModalV2';
import GoldrushMasterEventModal from '@/components/modals/GoldrushMasterEventModal';
import GenericTable from '@/components/GenericTable.vue';
import EventServiceV2 from '@/lib/event-service-v2';
import GoldrushServiceV2 from '@/lib/goldrush-service-v2';
import { isBefore, parseISO } from 'date-fns';

export default {
  components: {
    CreateGoldrushModal,
    ActivateGoldrushModal,
    EditFutureGoldrushRafflesModalV2,
    GoldrushMasterEventModal,
    GenericTable
  },
  data() {
    return {
      alert: {
        text: '',
        model: false
      },
      errorMessage: null,
      hideClosedDraws: true,
      manualWinningTicket: '',
      masterEventState: 'none',
      masterEventData: {},
      pickingWinner: false,
      raffleImage: null,
      allRaffles: [],
      canEditFutureRaffles: false,
      loading: false,
      data: [],
      columns: [
        {
          name: 'drawNum',
          label: 'Draw #',
          classes: 'w-auto'
        },
        {
          name: 'startDate',
          label: 'Start Date',
          classes: 'w-auto'
        },
        {
          name: 'endDate',
          label: 'End Date',
          classes: 'w-auto'
        },
        {
          name: 'status',
          label: 'Status',
          classes: 'w-auto'
        },
        {
          name: 'startingPot',
          label: 'Starting Pot',
          classes: 'w-auto'
        },
        {
          name: 'jackpot',
          label: 'Jackpot',
          classes: 'w-auto'
        },
        {
          name: 'winnerTicketNumber',
          label: 'Winning Ticket #',
          classes: 'w-auto'
        },
        {
          name: 'winnerName',
          label: 'Customer',
          classes: 'w-auto'
        },
        {
          name: 'ticketActive',
          label: 'Winner',
          classes: 'w-auto'
        }
      ],
      pagination: {
        total: 0,
        pageSize: 10,
        page: 0,
        sortBy: 'id',
        sortDir: 'asc'
      }
    };
  },
  watch: {
    hideClosedDraws: function () {
      this.getDisplayedRaffles();
    }
  },
  methods: {
    async onFutureRafflesUpdated() {
      await this.getDisplayedRaffles();
    },
    handleQuit() {
      this.errorMessage = null;
    },
    async sort(sortBy, sortDir) {
      this.pagination.sortBy = sortBy;
      this.pagination.sortDir = sortDir;
      await this.getDisplayedRaffles();
    },
    async updateCurrentPage(page) {
      this.pagination.page = page;
      await this.getDisplayedRaffles();
    },
    async getDisplayedRaffles() {
      this.loading = true;

      if (!this.masterEventData.id) {
        this.loading = false;
        return [];
      }

      const params = {
        page: this.pagination.page,
        sortBy: 'draw_num',
        sortDir: this.pagination.sortDir,
        pageSize: this.pagination.pageSize
      };

      if (!this.hideClosedDraws) {
        params.includeClosed = true;
      }

      try {
        const response = await GoldrushServiceV2.listEvents(this.masterEventData.id, params);

        this.pagination = response.pagination;
        this.raffleImage = response.data[0].logoUrl;
        this.loading = false;
        this.data = response.data;
      } catch (error) {
        this.handleError(`Failed to load Goldrush events: ${error}`);
        this.loading = false;
        return [];
      }
    },
    handleError(text) {
      this.alert.text = text;
      this.alert.model = true;
    },
    async getAllRaffles() {
      // Using this to gather all raffles in order to determine if any are in the future
      if (!this.masterEventData.id) {
        return [];
      }

      const params = {
        organizationId: this.$store.state.organization.id,
        goldrush: true,
        pageSize: 52
      };

      try {
        const response = await EventServiceV2.listEvents(params);
        const allRaffles = response.data;

        const futureRaffles = allRaffles.filter((raffle) => {
          return isBefore(Date.now(), parseISO(raffle.drawDate));
        });

        // Determine if the modal should try to load a raffle or tell the user they don't have any that are editable
        this.canEditFutureRaffles = futureRaffles.length > 0;
      } catch (error) {
        this.handleError(`Failed to load Goldrush events: ${error}`);
        return [];
      }
    }
  },
  async created() {
    try {
      const response = await GoldrushServiceV2.listMasterEvent({
        organizationId: this.$store.state.organization.id
      });

      if (!response.data[0].id) {
        this.masterEventState = 'none';
      } else {
        this.masterEventData = { ...response.data[0] };
        this.masterEventState = response.data[0].activated ? 'activated' : 'created';
      }
    } catch (error) {
      this.handleError(`Failed to load Goldrush event series ${error}`);
      return;
    }

    await this.getAllRaffles();
    await this.getDisplayedRaffles();
  }
};
</script>

<style scoped>
.container {
  text-align: left;
  margin-left: 115px;
  max-width: 1500px;
}
.raffle-table {
  margin-top: 2rem;
}
.event-table {
  margin-top: 2rem;
}
.pick-winner-container {
  padding-top: 1rem;
}
.rb-link {
  border: 0px none;
  color: #000000;
}
.rb-link-icon {
  min-width: 2.5rem;
  color: #000000;
}
</style>
