<template>
  <div>
    <b-collapse id="goldrush-master-event-collapse" class="mt-2">
      <b-card>
        <div class="grid grid-cols-3 gap-8 mt-3 mb-16 px-4">
          <div>
            <h4>{{ masterEventData.name }}</h4>
            <div class="grid grid-cols-2 gap-8 mt-4">
              <div class="flex flex-col">
                <p class="mb-0"><strong>Description: </strong>{{ masterEventData.description }}</p>
                <p class="mb-0">
                  <strong>Start date: </strong>{{ formatDateTime(parseISO(masterEventData.startDate)) }}
                </p>
                <p class="mb-0"><strong>End date: </strong>{{ formatDateTime(parseISO(masterEventData.endDate)) }}</p>
                <p class="mb-0"><strong>Auto recurring: </strong>{{ masterEventData.autoRecurring ? 'Yes' : 'No' }}</p>
                <p class="mb-0"><strong>Status: </strong>{{ masterEventData.activated ? 'Active' : 'Pending' }}</p>
              </div>
              <div class="flex flex-col">
                <p class="mb-0"><strong>Days per event: </strong>{{ masterEventData.daysPerDraw }}</p>
                <p class="mb-0"><strong>Number of events: </strong>{{ masterEventData.numDraws }}</p>
                <p class="mb-0"><strong>Starting ticket number: </strong>{{ masterEventData.startTicketNumber }}</p>
                <p class="mb-0">
                  <strong>Print individual tickets: </strong>{{ masterEventData.printIndividualTickets ? 'Yes' : 'No' }}
                </p>
              </div>
            </div>
          </div>
          <div class="flex items-center max-w-1/2">
            <img :src="logoUrl" :alt="`${masterEventData.name} logo`" />
          </div>
        </div>
        <div class="px-4">
          <b-table striped hover :fields="fields" :items="masterEventData.ticketPackages" />
        </div>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    masterEventData: {
      type: Object,
      required: true
    },
    logoUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      fields: [
        { label: 'Tickets', key: 'numTickets' },
        { label: 'Price', key: 'price' },
        { label: 'Series', key: 'series' },
        { label: 'Active', key: 'availableForPurchase' }
      ]
    };
  }
};
</script>
